<template>
  <div class="container full-height">
    <div class="row password-row">
      <div class="col-12 col-lg-6">
        <h1 class="mb-4 ms-4">Reset password</h1>
        <div class="auth-img">
          <app-image src="batters-info.png" alt="Batters Lifecycle" />
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <app-form
          :form="form"
          :model="model"
          :rules="rules"
          button="Reset"
          @submitted="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { form, model, rules } from '@/utils/config/reset_password';
import Database from '@/utils/services/Database';
import Utilities from '@/utils/services/Utilities';

export default {
  name: 'ResetPassword',
  data() {
    return {
      form,
      model,
      rules,
    };
  },
  methods: {
    async handleSubmit(data) {
      const token = this.$route.params.token;
      const status = await Database.update('tokens', token, data);

      if (status !== 200)
        return Utilities.showMessage(
          'error',
          'There was an error resetting your password'
        );
      else {
        Utilities.showMessage('success', 'Your password has been reset');
        this.$router.push({ name: 'Auth' });
      }
    },
  },
};
</script>

<style lang="scss">
.password-row {
  margin-top: 3rem;
  h1 {
    font-size: 1.8rem;
    font-weight: 500;
  }
  .card-static {
    padding: 2rem 1rem;
  }
}
</style>
